@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common.css
 Style : common
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
#global_header, #contents_wrap, #global_footer {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0.05em;
  font-size: 15px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 520px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-top: 30px;
  }
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 30px 50px 30px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #1f4589;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  z-index: 2;
  position: relative;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 80px;
  }
}

#header {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1600px) {
  #header {
    padding: 0 0 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  #header {
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header {
    position: fixed;
    top: 0;
    z-index: 10;
    background: #fff;
  }
}

#header .inner {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

#header .inner .flxL {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxL #siteID {
    width: 90%;
  }
}

#header .inner .flxR {
  width: 85%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR {
    padding-right: 70px;
  }
}

#header .inner .flxR .hedinfo {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo {
    align-items: center;
    padding: 0;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .tel2 span {
    display: none;
  }
}

#header .inner .flxR .hedinfo .tel2 a {
  display: block;
  color: #333;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .tel2 a {
    background: #427321;
    padding: 10px 15px;
    color: #fff;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
}

#header .inner .flxR .hedinfo .tel2 a .txt {
  font-size: 28px;
  font-weight: 700;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .hedinfo .tel2 a .txt {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .tel2 a .txt {
    font-size: 20px;
  }
}

#header .inner .flxR .hedinfo .tel2 a .txt i {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .tel2 a .txt i {
    margin-right: 0;
  }
}

#header .inner .flxR .hedinfo .tel2 a .txt2 {
  line-height: 1;
  display: block;
  margin-top: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .tel2 a .txt2 {
    display: none;
  }
}

#header .inner .flxR .hedinfo .cont {
  min-width: 230px;
  margin-left: 20px;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .cont {
    min-width: auto;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .cont span {
    display: none;
  }
}

#header .inner .flxR .hedinfo .cont a {
  border-radius: 10px;
  height: 100%;
  background: #f0a722;
  padding: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR .hedinfo .cont a {
    flex-direction: row;
  }
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .cont a {
    height: 60px;
    width: 60px;
    font-size: 16px;
  }
}

#header .inner .flxR .hedinfo .cont a:hover {
  opacity: 1;
  background: #ffcc70;
}

#header .inner .flxR .hedinfo .cont a .txt {
  font-weight: 700;
}

#header .inner .flxR .hedinfo .cont i {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #header .inner .flxR .hedinfo .cont i {
    font-weight: 700;
    margin-right: 0;
    font-size: 16px;
  }
}

#hednav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.navwrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navwrap #nav_global {
  width: 100%;
  padding: 28px 0px 17px;
  background: url(../images/common/pt_2.png) left top repeat-x;
}

.navwrap #nav_global > ul {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global > ul {
    max-width: 100%;
  }
}

.navwrap #nav_global > ul > li {
  position: relative;
  flex-grow: 1;
  border-right: 1px solid #ccc;
}

.navwrap #nav_global > ul > li:first-child {
  border-left: 1px solid #ccc;
}

.navwrap #nav_global > ul > li > p > a {
  display: block;
  color: #333;
  font-weight: 500;
  padding: 15px 30px;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.navwrap #nav_global > ul > li > p > a:hover {
  color: #f0a722;
  opacity: 1;
}

.navwrap #nav_global > ul > li > a {
  display: block;
  color: #333;
  font-weight: 500;
  padding: 15px 30px;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.navwrap #nav_global > ul > li > a:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f0a722;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.18, 0.65, 0.08, 1.01);
  transition: all 0.8s cubic-bezier(0.18, 0.65, 0.08, 1.01);
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global > ul > li > a:before {
    display: none;
  }
}

.navwrap #nav_global > ul > li > a:hover {
  color: #f0a722;
  opacity: 1;
}

.navwrap #nav_global > ul > li > a:hover:before {
  opacity: 1;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.navwrap #nav_global > ul > li > a span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
}

.navwrap #nav_global > ul > li ul {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 44px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0a722;
}

.navwrap #nav_global > ul > li ul li {
  flex-grow: 1;
}

.navwrap #nav_global > ul > li ul li a {
  background: #427321;
  display: block;
  padding: 20px 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 13px;
  color: #fff;
  opacity: 1;
}

.navwrap #nav_global > ul > li ul li a:after {
  content: "\f0a9";
  margin-left: 10px;
  font-family: "Font Awesome 5 pro";
}

.navwrap #nav_global > ul > li ul li a:hover {
  background: #8dc737;
}

.navwrap #hed_info {
  display: flex;
  align-items: center;
  display: none;
}

@media only screen and (max-width: 834px) {
  .navwrap #hed_info {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .navwrap #hed_info .store1 {
    padding: 5px;
    width: 100%;
    height: auto;
  }
  .navwrap #hed_info .store1 a {
    background: url(../images/common/pt_1.gif) left center repeat;
    width: 100%;
    display: block;
    padding: 15px 20px !important;
  }
  .navwrap #hed_info .store1 a img {
    display: none;
  }
}

.navwrap #hed_info .store1 a {
  display: block;
  color: #fff;
}

.navwrap #hed_info .store1 a .txt01 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navwrap #hed_info .store1 a .txt01 span {
  margin-left: 8px;
  font-weight: 700;
  font-size: 30px;
  color: #427321;
}

@media only screen and (max-width: 1000px) {
  .navwrap #hed_info .store1 a .txt01 span {
    color: #fff;
    margin-left: 0;
    font-size: 35px;
  }
}

.navwrap #hed_info .store1 a .txt02 {
  margin-top: 5px;
  line-height: 1;
  font-size: 12px;
  color: #fff;
}

.navwrap #hed_info .store2 {
  margin-left: 20px;
}

@media only screen and (max-width: 1000px) {
  .navwrap #hed_info .store2 {
    margin-left: 0;
    padding: 5px;
    width: 100%;
    height: auto;
  }
  .navwrap #hed_info .store2 a {
    background: url(../images/common/pt_1.gif) left center repeat;
    width: 100%;
    display: block;
    padding: 15px 20px !important;
    height: 100%;
  }
}

.navwrap #hed_info .store2 a {
  display: block;
  background: url(../images/common/pt_1.gif) left center repeat;
  padding: 10px 30px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

@media only screen and (max-width: 1200px) {
  .navwrap #hed_info .store2 a {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .navwrap #hed_info .store2 a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}

.navwrap #hed_info .store2 a i {
  margin-right: 10px;
}

.global-menu-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    cursor: pointer;
    border-radius: 5px;
    height: 60px;
    width: 60px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 11;
    color: #f0a722;
    line-height: 1;
    transition: 1s;
    color: #fff;
    background: #f0a722;
  }
  .global-menu-btn:hover {
    color: #222;
    background: #fff;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    height: 100px;
    width: 100px;
    margin-left: auto;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .global-menu-close-btn {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

.navdamy {
  display: none;
}

@media only screen and (max-width: 834px) {
  .navdamy {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    margin-bottom: 0;
    justify-content: center;
    height: 60px;
    width: 60px;
    z-index: 11;
    top: 10px;
    right: 10px;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    top: 0;
    right: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 70%;
    height: 100%;
    opacity: 0;
    margin-left: auto;
    -webkit-transform: translateX(240%);
    -ms-transform: translateX(240%);
    transform: translateX(240%);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    justify-content: space-around;
    align-items: normal !important;
    width: 90%;
    margin: 5% auto;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul {
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global > ul .pcnone {
    display: block !important;
  }
  #hednav .wrap .navwrap #nav_global > ul > li {
    text-align: left;
    position: relative;
    width: 100%;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a {
    line-height: 1.7em;
    color: #222 !important;
    font-weight: 700 !important;
    display: block;
    padding: 25px 36px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a img {
    width: 25%;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span {
    width: 70%;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span b {
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span span {
    display: block;
    font-size: 0.8em;
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul {
    width: 100%;
    position: static;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul li a {
    background: #f7f7f7;
    color: #333;
    font-weight: 500;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p {
    position: relative;
    display: block;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span {
    display: block;
    margin-top: 7px;
    font-size: 0.7em;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:after {
    border: solid 1px #ddd;
    font-weight: 100;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    display: block;
    position: absolute;
    right: 0;
    top: -1px;
    bottom: 0;
    width: 77px;
    height: 77px;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f6;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p.open:after {
    content: "\f00d";
  }
}

.MenuOpened {
  z-index: 13 !important;
  display: none;
}

@media only screen and (max-width: 834px) {
  .MenuOpened {
    display: block;
  }
  .MenuOpened .global-menu-btn {
    z-index: 0;
  }
  .MenuOpened .wrap {
    z-index: 10 !important;
    visibility: visible !important;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .MenuOpened .wrap .navwrap {
    opacity: 1 !important;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh !important;
    margin-left: auto;
  }
  .MenuOpened .wrap .global-menu-close-btn {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

#mainArea2 {
  background-image: url(../images/contents/main_1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 834px) {
  #mainArea2 {
    margin-bottom: 80px;
  }
}

#local-keyvisual {
  overflow: hidden;
  position: relative;
  background-position: center;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  width: 100%;
}

#local-keyvisual #local-keyvisual-title h1 {
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  z-index: 1;
  position: relative;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.4em;
  padding: 100px 10px;
  text-align: center;
  font-size: 38px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 28px;
  }
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual .local-keyvisual-bg {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pan1 {
  margin: 20px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #221815;
}

#mainArea {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 834px) {
  #mainArea {
    padding-top: 0 !important;
  }
}

#mainArea ul {
  max-width: 1860px;
  margin: 0 auto;
}

#mainArea img {
  width: 100% !important;
}

#mainArea button {
  display: none !important;
}

footer#global_footer {
  background: #f0a722;
  width: 100%;
  min-width: 100% !important;
  height: auto;
  z-index: 1;
  position: relative;
  padding-bottom: 89px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    background: none;
    padding-bottom: 0;
  }
}

footer#global_footer a {
  color: #f0a722;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

iframe {
  vertical-align: middle;
}

#footer2 {
  color: #333;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
}

#footer2 .svg01 {
  vertical-align: bottom;
}

#footer2 svg {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #footer2 svg {
    display: none !important;
  }
}

#footer2 .inner {
  max-width: 1200px;
  padding: 50px 0;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner {
    padding: 50px 30px;
  }
}

#footer2 .inner p {
  color: #fff;
  margin-bottom: 30px;
  width: 100%;
}

#footer2 .inner .ttl {
  font-weight: 700;
  color: #fff;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner .ttl {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .ttl {
    font-size: 20px;
  }
}

#footer2 .inner {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#footer2 .inner .wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap {
    display: block;
    width: 100%;
  }
}

#footer2 .inner .wrap dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 60%;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 0;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap dl {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner .wrap dl:nth-child(2) {
    margin-bottom: 20px;
  }
}

#footer2 .inner .wrap dl:nth-child(2) dt {
  background: #427321;
}

#footer2 .inner .wrap dl dt {
  width: 100%;
  background: #f0a722;
  margin-bottom: 30px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  font-weight: 700;
  color: #fff;
  padding: 20px 10px;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner .wrap dl dt {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap dl dt {
    font-size: 20px;
  }
}

#footer2 .inner .wrap dl dt span {
  font-size: 16px;
}

@media only screen and (max-width: 640px) {
  #footer2 .inner .wrap dl dt span {
    display: block;
  }
}

#footer2 .inner .wrap dl dd {
  width: 100%;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap dl dd {
    display: block;
    margin: 0;
  }
}

#footer2 .inner .wrap dl dd a {
  width: 48%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap dl dd a {
    width: 90%;
    margin: 10px auto;
  }
}

#footer2 .inner .wrap dl dd a img {
  max-width: 315px;
  opacity: 1 !important;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner .wrap dl dd a img {
    width: 100%;
    max-width: 100%;
  }
}

#footer2 .inner .wrap dl dd a:hover {
  opacity: 1 !important;
}

#footer2 .inner .wrap dl:nth-child(2) {
  width: 38%;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .wrap dl:nth-child(2) {
    width: 100%;
    margin-top: 30px;
  }
  #footer2 .inner .wrap dl:nth-child(2) img {
    width: 90%;
  }
}

#footer2 .inner .wrap dl:nth-child(2) a {
  width: 100%;
}

#copy {
  background: #f0a722;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #f0a722;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #f0a722;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #f0a722;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn {
  overflow: hidden;
  position: relative;
  display: block;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 10px;
}

.btn .su_button_circle {
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
}

.btn a {
  border-radius: 10px;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  font-size: 18px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
  color: #fff;
  background: #f0a722;
}

.btn a:hover {
  opacity: 1 !important;
}

.btn a span {
  position: relative;
  z-index: 1;
}

.btn.gla01 {
  background-image: -moz-linear-gradient(-180deg, #8fd1f3 0%, #a092d4 100%);
  background-image: -webkit-linear-gradient(-180deg, #8fd1f3 0%, #a092d4 100%);
  background-image: -ms-linear-gradient(-180deg, #8fd1f3 0%, #a092d4 100%);
}

.btn.gla01 .explode-circle {
  animation: explode 0.5s forwards;
}

.btn.gla01 .desplode-circle {
  animation: desplode 0.5s forwards;
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(255, 204, 112, 0.2);
  }
  100% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(255, 204, 112, 0.8);
  }
}

@keyframes desplode {
  0% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(255, 204, 112, 0.8);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(255, 204, 112, 0.6);
  }
}

.hoverimg a:hover {
  opacity: 1 !important;
}

.hoverimg .box {
  position: relative;
}

.hoverimg .box img {
  opacity: 1 !important;
}

.hoverimg .box a:hover {
  opacity: 1 !important;
}

.hoverimg .box::before, .hoverimg .box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.hoverimg .box::before {
  transform-origin: left top;
}

.hoverimg .box::after {
  transform-origin: right bottom;
}

.hoverimg .box:hover::before, .hoverimg .box:hover::after, .hoverimg .box:focus::before, .hoverimg .box:focus::after {
  transform: scale3d(1, 1, 1);
}

.cont_1 {
  background: #eaf8eb;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 0px 0;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    margin-bottom: 80px !important;
  }
}

.cont_1 .svg02 {
  transform: scale(1, -1);
  vertical-align: bottom;
}

.cont_1 svg {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .cont_1 svg {
    display: none !important;
  }
}

.cont_1 .inner {
  padding: 50px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner {
    padding: 50px 30px;
  }
}

.cont_1 .inner .wrap {
  justify-content: space-between !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap {
    display: block;
  }
}

.cont_1 .inner .wrap .box {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  width: 32%;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_1 .inner .wrap .box a {
  display: block;
}

.cont_1 .inner .wrap .box img {
  border-radius: 15px;
  opacity: 1 !important;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap .box img {
    width: 100% !important;
  }
}

.cont_1 .inner .wrap .box h3 {
  display: block;
  margin: 30px 0 20px 0;
  color: #222;
  font-weight: 700;
  font-size: 18px;
}

.cont_1 .inner .wrap .box article > div {
  color: #333;
}

.cont_1 .inner .wrap .box article > div .btn {
  margin-top: 20px;
  border-radius: 10px;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 20px 10px;
  line-height: 1;
  color: #fff;
  background: #f0a722;
}

.cont_1 .inner .wrap2 {
  justify-content: space-between !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap2 {
    display: block;
  }
}

.cont_1 .inner .wrap2 .box {
  width: 32%;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap2 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_1 .inner .wrap2 .box a {
  display: block;
}

.cont_1 .inner .wrap2 .box img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .wrap2 .box img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 {
    margin-bottom: 80px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_2 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .inner {
    padding: 0 30px;
  }
}

.cont_5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_5 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_5 {
    padding: 0 30px;
    display: block !important;
    margin-bottom: 80px !important;
  }
}

.cont_5 .box {
  width: 100%;
  max-width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_5 .box {
    margin: auto;
    max-width: 80%;
    margin-bottom: 30px;
  }
}

.cont_5 .box img {
  width: 100% !important;
}

.cont_3 {
  background: #eaf8eb;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 0px 0;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_3 {
    margin-bottom: 80px !important;
  }
}

.cont_3 .svg02 {
  transform: scale(1, -1);
  vertical-align: bottom;
}

.cont_3 svg {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .cont_3 svg {
    display: none !important;
  }
}

.cont_3 .wrap {
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .wrap {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .wrap {
    padding: 50px 30px;
  }
}

.newswrap {
  height: 400px;
}

.newswrap .news {
  overflow-y: auto;
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 40px;
}

.newswrap .news .sns_list {
  border: 0;
}

.newswrap .news .sns_list > div {
  border-bottom: 1px dashed #333;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news .sns_list > div {
    flex-direction: row !important;
  }
}

.newswrap .news .sns_list .sns_photo {
  padding: 0;
  margin-right: 3%;
}

.newswrap .news .sns_list .sns_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newswrap .news .sns_list .sns_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.newswrap .news .sns_list .sns_text .detawrap .wrapdeta .sns_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #222;
}

.newswrap .news .sns_list .sns_text .detawrap .wrapdeta ul {
  display: none;
}

.newswrap .news .sns_list .sns_text h3 {
  margin-bottom: 0;
}

.newswrap .news .sns_list .sns_text h3 a span {
  display: block;
  font-weight: 300;
  font-size: 16px;
  color: #333;
}

.newswrap .news .sns_list .sns_text .detail {
  display: none;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newswrap .news dl dt {
  font-weight: 700;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_4 {
  padding: 0px 0;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_4 {
    margin-bottom: 80px !important;
  }
}

.cont_4 .svg02 {
  transform: scale(1, -1);
  vertical-align: bottom;
}

.cont_4 svg {
  width: 100%;
}

.cont_4 .wrap {
  margin: 0 auto;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_4 .wrap {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .wrap {
    padding: 0 30px;
  }
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi h2 {
  position: relative;
  font-weight: 800;
  line-height: 1.7em;
  color: #222;
  font-size: 40px;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi h2:after {
  content: "";
  margin: auto;
  display: block;
  margin-top: 20px;
  width: 140px;
  height: 15px;
  background: url(../images/common/pt_1.png) center repeat-x;
}

@media only screen and (max-width: 834px) {
  .midashi h2:after {
    display: none;
  }
}

.midashi2 h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-weight: 800;
  line-height: 1.7em;
  color: #222;
  font-size: 40px;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi2 h2:after {
  content: "";
  display: block;
  width: 175px;
  height: 15px;
  margin-left: 15px;
  background: url(../images/common/pt_1.png) center repeat-x;
}

@media only screen and (max-width: 834px) {
  .midashi2 h2:after {
    display: none;
  }
}

.midashi4 h2 {
  background: #f7f7f7;
  padding: 15px 20px 15px;
  position: relative;
  border: 1px solid #ccc;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h2:before {
  content: "";
  display: block;
  width: 4px;
  height: 15px;
  border-radius: 500px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #427321;
}

.midashi5 h2 {
  background: #f7f7f7;
  padding: 15px 20px 15px;
  position: relative;
  border: 1px solid #ccc;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi5 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi5 h2 {
    font-size: 20px;
  }
}

.midashi5 h2 span {
  background: #f0a722;
  padding: 10px;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  line-height: 1;
  display: inline-block;
  margin-right: 10px;
}

.midashi6 h2 {
  font-weight: 700;
  color: #427321;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi6 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi6 h2 {
    font-size: 18px;
  }
}

.midashi7 h3 {
  font-weight: 700;
  color: #222;
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .midashi7 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi7 h3 {
    font-size: 18px;
  }
}

.beauty01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.beauty01 .flxL {
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .beauty01 .flxL {
    width: 100%;
  }
}

.beauty01 .flxR {
  width: 65%;
}

@media only screen and (max-width: 640px) {
  .beauty01 .flxR {
    margin-top: 20px;
    width: 100%;
  }
}

.flow01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flow01 .flxL {
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .flow01 .flxL {
    width: 100%;
  }
}

.flow01 .flxR {
  width: 65%;
}

@media only screen and (max-width: 640px) {
  .flow01 .flxR {
    margin-top: 20px;
    width: 100%;
  }
}

.about01 {
  border: 5px solid #f0f0f0;
  padding: 30px;
  border-radius: 10px;
}

.about01 h3 {
  margin-bottom: 20px;
  font-weight: 700;
  color: #222;
  font-size: 20px;
  padding-bottom: 12px;
  border-bottom: 4px double #222;
}

@media only screen and (max-width: 1200px) {
  .about01 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .about01 h3 {
    font-size: 18px;
  }
}

.corpon01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 5px solid #f0f0f0;
  padding: 30px;
  border-radius: 10px;
}

.corpon01 .flxL {
  width: 35%;
}

@media only screen and (max-width: 834px) {
  .corpon01 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
  .corpon01 .flxL img {
    width: 100% !important;
  }
}

.corpon01 .flxR {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .corpon01 .flxR {
    width: 100%;
  }
}

.comp2 dt, .comp2 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.comp2 dt {
  border-bottom: 1px solid #ccc !important;
}

.comp2 dd {
  border-bottom: 1px solid #eee !important;
}

@media only screen and (max-width: 834px) {
  .comp2 dl {
    border: 1px solid #ccc !important;
    display: flex;
    flex-flow: column;
  }
  .comp2 dt {
    border-bottom: 0 !important;
    background: #f7f7f7 !important;
    font-weight: bold;
    border-bottom: 1px solid #ccc !important;
    text-align: center !important;
  }
  .comp2 dt, .comp2 dd {
    padding: 5px !important;
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }
}

.works01 {
  overflow-x: hidden;
}

.works01 .owl-stage {
  display: flex;
}

.works01 .owl-nav, .works01 .owl-dots {
  display: none;
}

.works01 .sns_list {
  border: 0;
}

.works01 .sns_list li {
  display: block !important;
  border-bottom: 0 !important;
}

.works01 .sns_list li .sns_photo {
  padding: 0 !important;
  width: 100% !important;
}

.works01 .sns_list li .sns_text {
  width: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
}

.works01 .sns_list li .sns_text .sns_date {
  color: #222;
  margin: 10px 0 0px;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.event_Instagram02 .sns_photo {
  width: 40% !important;
}

@media screen and (max-width: 640px) {
  .event_Instagram02 .sns_photo {
    width: 100% !important;
  }
}

.event_Instagram02 .sns_date {
  background: #f0a722;
  color: #fff;
  padding: 0.3em 1em;
  color: #fff !important;
  font-size: 1rem;
}

.event_Instagram02 .clearfix a {
  color: #f0a722 !important;
}

.event_Instagram02 .caption {
  padding: 1em 0;
  font-size: 1rem;
}

.event_Instagram02 div.sns_list > div {
  padding: 40px;
}

@media screen and (max-width: 640px) {
  .event_Instagram02 div.sns_list > div {
    padding: 10px;
  }
}

.event_Instagram02 .sns_list {
  border: 0px dashed #ccc !important;
}

.contact01 a {
  display: inline-block;
  background: #f0a722;
  padding: 30px;
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.contact01 a span {
  display: block;
  font-size: 20px;
  margin-top: 10px;
}

.corpon02 dl {
  display: block !important;
}

.corpon02 dl dt, .corpon02 dl dd {
  width: 100% !important;
  border: 0 !important;
}

.corpon02 dl dt {
  background: #f0a722 !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
}

.corpon02 dl dd {
  border: 1px solid #f0f0f0 !important;
  background: #f3f3f3 !important;
  border-radius: 0 5px 5px 0;
}

.ext_01 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.ext_01 .box {
  margin-bottom: 20px;
  width: 48%;
  text-align: center;
}

.ext_01 .box img {
  width: 100% !important;
}

.ext_01 .box > div {
  margin-top: 15px;
}

.ext_01 .box h3 {
  display: none;
}

.ext_02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .ext_02 {
    flex-wrap: wrap;
  }
}

.ext_02:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_02 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_02 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_02 .flxR {
    width: 100%;
  }
}

.ext_02 .flxR img {
  width: 100% !important;
}

.ext_03 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .ext_03 {
    flex-wrap: wrap;
  }
}

.ext_03:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_03 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_03 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_03 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 834px) {
  .ext_03 .flxR {
    width: 100%;
  }
}

.ext_03 .flxR img {
  width: 100% !important;
}

.waku {
  padding: 40px;
  background: #fff;
  border: double 7px #f0a722;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.waku h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid #4e362a;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #222;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.5vw;
}

@media only screen and (min-width: 1600px) {
  .waku h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .waku h3 {
    font-size: 18px;
  }
}

.media3 .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media3 .sns_list {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .media3 .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media3 .sns_list > div {
  border-bottom: 0;
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 2.43902%;
  display: block;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media3 .sns_list > div {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .media3 .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media3 .sns_list > div:nth-child(n+7) {
  margin-bottom: 0;
}

.media3 .sns_list > div .sns_text {
  width: 100%;
  font-size: 16px;
}

.media3 .sns_list > div .sns_text .sns_date {
  color: #0b0b0b;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #0b0b0b;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.media3 .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media3 .sns_list > div .sns_photo a {
  display: block;
  position: relative;
}

.media3 .sns_list > div .sns_photo a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.media3 .sns_list > div .sns_photo img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
